<template>
     <div class="loading" v-if="loader">
    <center>
        <div class="content" style="margin-left: -102px;margin-top: -133px;">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
      </div>
    </center>
  </div>

    <div class="container mainb" style="background-color: #fcd1ba;">
        <div class="section-title">
            <h2>{{id}}</h2>
            <p>All price are exclusive of GST</p>
        </div>
    </div>
    <div class="container-fluid vu">
        <div class="row" style=" justify-content:center; display:flex; align-items:center;">

            <div class="team" style="justify-content: center; display: flex; flex-wrap: wrap; align-items: center; ">



                <div v-for="categ in categoriesitem" :key="categ" class=" mt-0.5" style="width: 115px;height:155px">
                    <div class="member d-flex align-items-start" style="height: 150px; width: 112px;" data-toggle="modal" data-target="#modal-default" v-on:click="categoriesFunction(categ)">

                        <a style="cursor: pointer;">

                            <div class="pic">
                                <img :src="getcategory(categ.img)" class="img-fluid" alt="" style="height:90px; width:90px">
                                
                                <b><span style="color:red">{{categ.price}}</span></b>
                                <p class="block-ellipsis text">{{categ.itemName}}</p>
                            </div>
                        </a>
                    </div>
                </div>


            </div>
        </div>
    </div>
    <button type="button" class="btn btn-primary btn-floating  back-to-top" @click="backbtn" style="background-color: #9c4a29;border-radius: 35px;float: right;margin-top: 10%;">
        <i class="fas fa-arrow-left" style="color:white"></i>
    </button>    <!-- ======= Footer ======= -->
  
    <footer id="footer" class="footer">

    <div class="container footer-bottom clearfix">

        <div class="social" style="vertical-align: middle; float: left; display: inline-block; line-height: 2;">
            <a style="color: #fcd1ba;margin: 7px;" href="tel:9016646452"><i class="fa-solid fa-phone"></i></a>
            <a style="color: #fcd1ba;margin: 7px;" href="https://www.facebook.com/woodlandsresturantvapi/"><i class="fa-brands fa-facebook-f" style="color:bisque"></i></a>
            <a style="color: #fcd1ba;margin: 7px;" href="https://www.instagram.com/woodlandsrestaurantofficial/?hl=en"><i class="fa-brands fa-instagram"></i></a>
            <a style="color: #fcd1ba;margin: 7px;" href="tel:02606615583"><i class="fa-solid fa-phone"></i></a>

        </div>
        

        <div style="float: right; display: inline-block;">
            <router-link class="nav-link " to="/">
                <img src="/Img/logo1.png" class="img-fluid">
                  
                </router-link>
               
        </div>

    </div>

    </footer>

<div class="modal fade "  id="modal-default" @close="showModal = false">
        <div class="modal-dialog ">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title text-uppercase"></h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <h3>{{title}}</h3>
                <center><img :src="image" class="img-fluid text-center" alt="" style="height:200px; width:200px"></center>
                <p style="color: black; font-size:12px;text-align: center;">Price:<b>{{this.price}}</b></p>
                <p style="color: black; font-size:12px;text-align: center;">Description:<b>{{this.description}}</b></p>
            </div>     
            <div class="modal-footer justify-content-between">
              <button type="button" id="close" class="btn btn-default" data-dismiss="modal"  @click="close">Close</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>

</template>

<script>
    import axios from 'axios'

    export default {
        name: 'HelloWorld',
        beforeMount() {

            this.getcateItem();

        },
        data() {
            return {
                id: this.$route.params.url, //this is the id from the browser
                categoriesitem:[],
                title:'',
                price:'',
                description:'',
                image:'',
                loader:false,
            }
        },
        methods: {
            backbtn(){
                this.$router.push('/Home');
            },
            categoriesFunction(e){
                console.log(e)
                
                this.title=e.itemName;
                this.price=e.price;
                this.description=e.desc;
                this.image=this.getcategory(e.img)
            },  
           getcategory(imgpath) {
                return 'https://woodlands.areteonline.in/Img/item/' + imgpath;
            },
            getcateItem() {
                this.loader=true;

                let itemid = this.id;
                axios.get('https://wdlapi.areteonline.in/api/items/' + itemid, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then((res) => {
                        console.log(res.data)
                        this.categoriesitem = res.data;
                        this.loader=false;
                    })
                    .catch((error) => {
                        console.error(error)
                        this.loader=false;
                    })

            },
        }
    }
</script>
<style>
.text {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
}
    html {
        background-color: lightgreen;
    }
    #nav {
        background-color: lightgreen;
    }
    .section-title {
        text-align: center;
        padding-bottom: 10px;
    }
    .mainb {
        justify-content: center;
        align-items: center;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 10px;
        background-color: lightgreen;
    }
    .section-title h2::before {
        content: "";
        position: absolute;
        display: block;
        width: 120px;
        height: 1px;
        background: #ddd;
        bottom: 1px;
        left: calc(50% - 60px);
    }
    .section-title h2::after {
        content: "";
        position: absolute;
        display: block;
        width: 40px;
        height: 3px;
        background: #47b2e4;
        bottom: 0;
        left: calc(50% - 20px);
        top: 7%;
    }
    .back-to-top:hover {
        background: #6bc1e9;
        color: #fff;
    }
    .back-to-top {
    position: fixed;
    visibility: visible;
    opacity: 1;
    right: 5px;
    bottom: 55px;
    z-index: 996;
    background: #9c4a29;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    transition: all 0.4s;
}
</style>