<template>
  <div class="loading" v-if="loader">
      <div class="content" style="margin-left: -102px;margin-top: -133px;">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
      </div>
  </div>

    <div>
        <section class="hero" id="hero">
          <Carousel :autoplay="2000" :wrap-around="true">
    <Slide v-for="slide in banners" :key="slide">
      <div class="carousel__item">
        <img :src="getImage1(slide.img)"  class="img-fluid d-block w-100 peinture-ombre-interne-fine" alt="..."  style="height:169px;max-width: 100%;">

      </div>
    </Slide>

    <template #addons>
      
    </template>
  </Carousel>
  
  <!-- ombre-interne -->
  <!-- ombre-externe -->
  
  
  
  </section>
        <section class="" style=" justify-content:center; display:flex; align-items:center;padding-left:5px;padding-right:5px;padding-top:0px;">

<div class="container-fluid" style="overflow:hidden">
<div class="row" style=" justify-content:center; display:flex; align-items:center;">

<div class="team" style="justify-content: center; display: flex; flex-wrap: wrap; align-items: center; ">



    <div v-for="categ in categories" :key="categ" class=" mt-0.5" style="width: 115px;height:155px">
        <div class="member d-flex align-items-start" style="height: 150px; width: 112px;" v-on:click="categoriesFunction(categ.name)">


            <a style="cursor: pointer;">

                <div class="pic">
                    <img :src="getImage(categ.img)" class="img-fluid" alt="" style="height:90px; width:90px">

                    <p class="block-ellipsis">{{categ.name}}</p>


                </div>
            </a>
        </div>
    </div>


</div>
</div>
</div>
</section>
<footer id="footer" class="footer">

<div class="container footer-bottom clearfix">

    <div class="social" style="vertical-align: middle; float: left; display: inline-block; line-height: 2;">
        <a style="color: #fcd1ba;margin: 7px;" href="tel:9016646452"><i class="fa-solid fa-phone"></i></a>
        <a style="color: #fcd1ba;margin: 7px;" href="https://www.facebook.com/woodlandsresturantvapi/"><i class="fa-brands fa-facebook-f" style="color:bisque"></i></a>
        <a style="color: #fcd1ba;margin: 7px;" href="https://www.instagram.com/woodlandsrestaurantofficial/?hl=en"><i class="fa-brands fa-instagram"></i></a>
        <a style="color: #fcd1ba;margin: 7px;" href="tel:02606615583"><i class="fa-solid fa-phone"></i></a>

    </div>
    

    <div style="float: right; display: inline-block;">
        <router-link class="nav-link " to="/">
            <img src="/Img/logo1.png" class="img-fluid">
              
            </router-link>
           
    </div>

</div>

</footer>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel,  Slide } from 'vue3-carousel'

import axios from 'axios';

    export default {
        data() {
      return {
          // for new tasks
          loginCheck: false,
          name: '',
          mobile: '',
          banners: [],
          categories: [],
          loader:false,
        };
       },
       components: {
    Carousel,
    Slide
    }, 
    mounted() {

            this.getmethodColumn();
            this.getmethodColumn1();
            
            },
    methods: {
        categoriesFunction(main) {
          
          this.$router.push('categories/' + main);
      },
        getImage(imagePath) {
          return 'https://woodlands.areteonline.in/Img/cat/'+imagePath;
      },
        getImage1(imagePath) {
          return 'https://woodlands.areteonline.in/Img/Bann/'+imagePath;
      },
      async getmethodColumn1(){
        this.loader=true;
            try {
          var optionAxios = {
            headers: {
                      'Accept': 'text/plain'
                    }                         }
          const response = await axios.get(`https://wdlapi.areteonline.in/api/banners`,optionAxios)
          this.banners= response.data;
        this.loader=false;
             
          // Work with the response...
      } catch (err) {
          // Handle error
          // console.log(err);
          this.banners=[];
          this.loader=false;
      }



        },
       async getmethodColumn(){
        this.loader=true;
            try {
          var optionAxios = {
            headers: {
                      'Accept': 'text/plain'
                    }                         }
          const response = await axios.get(`https://wdlapi.areteonline.in/api/Categs`,optionAxios)
          this.categories= response.data;
          this.loader=false;

          // Work with the response...
      } catch (err) {
          // Handle error
          // console.log(err);
          this.categories=[];
          this.loader=false;

      }



        }
            }
    }
</script>

<style>
section {
    padding: 0px 0;
    overflow: hidden;
}
#hero {
width: 100%;
height: 250px;
background: #fcd1ba;
}
#hero .container {
padding-top: 0px;
}
#hero h1 {
margin: 0 0 10px 0;
font-size: 48px;
font-weight: 700;
line-height: 56px;
color: #fff;
}
#hero h2 {
color: rgba(255, 255, 255, 0.6);
margin-bottom: 50px;
font-size: 24px;
}
#hero .btn-get-started {
font-family: "Jost", sans-serif;
font-weight: 500;
font-size: 16px;
letter-spacing: 1px;
display: inline-block;
padding: 10px 28px 11px 28px;
border-radius: 50px;
transition: 0.5s;
margin: 10px 0 0 0;
color: #fff;
background: #47b2e4;
}
#hero .btn-get-started:hover {
background: #209dd8;
}
#hero .btn-watch-video {
font-size: 16px;
display: flex;
align-items: center;
transition: 0.5s;
margin: 10px 0 0 25px;
color: #fff;
line-height: 1;
}
#hero .btn-watch-video i {
line-height: 0;
color: #fff;
font-size: 32px;
transition: 0.3s;
margin-right: 8px;
}
#hero .btn-watch-video:hover i {
color: #47b2e4;
}
#hero .animated {
animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
@media (max-width: 991px) {
#hero {
height: 194PX;
text-align: center;
}
#hero .animated {
-webkit-animation: none;
animation: none;
}
#hero .hero-img {
text-align: center;
}
#hero .hero-img img {
width: 50%;
}
}
@media (max-width: 768px) {
#hero h1 {
font-size: 28px;
line-height: 36px;
}
#hero h2 {
font-size: 18px;
line-height: 24px;
margin-bottom: 30px;
}
#hero .hero-img img {
width: 70%;
}
}
@media (max-width: 575px) {
#hero .hero-img img {
width: 80%;
}
#hero .btn-get-started {
font-size: 16px;
padding: 10px 24px 11px 24px;
}
}
#footer {
  font-size: 22px;
  background: #37517e;
}
.footer {
  position: fixed;
  /*left: 0;*/
  bottom: 0;
  width: 100%;
  height: 50px;
  /*background-color: red;
      color: white;*/
  /*text-align: center;*/
}

#footer .footer-bottom {
  padding-top: 0px;
  padding-bottom: 0px;
}

.row {
  margin-bottom: 50px;
}
.team .member .pic {
  border-radius: 50%;
}
.team .member {
  position: relative;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
  padding: 5px;
  border-radius: 5px;
  background: #fff;
  transition: 0.5s;
  border: solid;
  border-color: #9c4a29;
}
  .team .member p {
      margin: 5px 0 0 0;
      font-size: 14px;
  }
  .team .member p {
      margin: 5px 0 0 0;
      font-size: 14px;
  }

.owl-nav .disabled {
  display: none;
}
#footer {
    font-size: 22px;
    background: #9c4a29;
}
:root {
	--sp: 2.5s; /* change speed */
}		


.content {
	width: 50vmin;
	height: 50vmin;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: scale(0.65);
	animation: spin-all calc(var(--sp) * 2) linear 0s infinite
}

.circle {
	--in: 80%;
	--ar: #9c4a29;
	--dt: #fcd1ba;
	--shadow: drop-shadow(0vmin 0vmin 0.5vmin #000) drop-shadow(0vmin 1vmin 0.5vmin #0004);
	--cross: linear-gradient(0deg, #fff0 calc(50% - 2px), #000 calc(50% - 1px) calc(50% + 1px), #fff0 calc(50% + 2px)), linear-gradient(90deg, #fff0 calc(50% - 2px), #000 calc(50% - 1px) calc(50% + 1px), #fff0 calc(50% + 2px));
    border: 6vmin solid #9c4a29;
    width: var(--in);
    height: var(--in);
    border-radius: 100%;
    position: absolute;
    box-sizing: border-box;
    border-top-color: #fcd1ba;
    border-left-color: #9c4a29;
    top: 15vmin;
    right: -10vmin;
    animation: spin-bot var(--sp) ease 0s infinite;
    background-image: var(--cross), radial-gradient(var(--dt) 5.5vmin, #fff0 calc(5.5vmin + 1px));
	background-repeat: no-repeat;
    background-size: 3vmin 1vmin, 1vmin 3vmin, 100% 100%;
    background-position: center center;
	filter: var(--shadow);
}

.circle:nth-child(2) {
    --in: 60%;
    top: -2vmin;
    animation: spin-top var(--sp) ease 0s infinite;
    transform: rotate(-45deg);
    background-image: var(--cross), radial-gradient(var(--dt) 1.25vmin, #fff0 calc(1.25vmin + 1px));
    right: -4vmin;
	filter: hue-rotate(10deg) var(--shadow);
    background-size: 1.4vmin 1vmin, 1vmin 1.4vmin, 100% 100%;
}

.circle:nth-child(3) {
    --in: 100%;
    top: -5vmin;
    left: -13vmin;
    transform: rotate(175deg);
    animation: spin-left var(--sp) ease calc(var(--sp) / 4) infinite;
    background-image: var(--cross), radial-gradient(var(--dt) 9vmin, #fff0 calc(9vmin + 1px));
	filter: hue-rotate(20deg) var(--shadow);
    background-size: 5vmin 1vmin, 1vmin 5vmin, 100% 100%;
}

.circle:nth-child(4) {
    --in: 60%;
    top: 35vmin;
    left: -6vmin;
    transform: rotate(-280deg);
    animation: spin-last var(--sp) ease calc(calc(calc(var(--sp) / 4) + var(--sp)) * -1) infinite;
    background-image: var(--cross), radial-gradient(var(--dt) 2.5vmin, #fff0 calc(2.5vmin + 1px));
	filter: hue-rotate(30deg) var(--shadow);
    background-size: 2vmin 1vmin, 1vmin 2vmin, 100% 100%;
}


@keyframes spin-all {
	0% { transform: rotate(0deg) scale(0.65); }
	100% { transform: rotate(360deg) scale(0.65); }
}


@keyframes spin-top {
	0% { transform: rotate(-45deg); }
	100% { transform: rotate(315deg); }
}

@keyframes spin-bot {
	0%, 35% { transform: rotate(0deg); }
	80%, 100% { transform: rotate(-360deg); }
}

@keyframes spin-left {
	0%, 35% { transform: rotate(175deg); }
	80%, 100% { transform: rotate(535deg); }
}

@keyframes spin-last {
	0%, 10% { transform: rotate(-280deg); }
	90%, 100% { transform: rotate(-640deg); }
}
/* loader */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

</style>