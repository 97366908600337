<template>
    <div>
        <p>Page Not Found</p>

    </div>
    <div>
        <router-link to="/"> Go To Home Page</router-link>
    </div>
</template>

<script>
    export default {

        mounted() {
            this.reidrect()
        },
        methods: {
            reidrect(){
                this.$router.push('/');
            }
        },
        
    }
</script>

<style lang="scss" scoped>

</style>