import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router' // <---
import store from './store/index'   
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
//import adminlte scripts
import 'vue3-carousel/dist/carousel.css';
import { Carousel } from 'vue3-carousel'
import VueCookies from 'vue-cookies'


import Vue3Storage from "vue3-storage";

const app = createApp({
    extends: App,
    created() {
     // this.$store.dispatch('loadStoredState')
    //  this.$store.dispatch('loadUser')
    
    },
  })

  app.use(router)
  app.use(store)
  app.use(VueSweetalert2)
  app.use(Vue3Storage)
  app.use(Carousel)
  app.use(VueCookies)
app.mount('#app')