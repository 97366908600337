<template>

  
  <div id="nav" style="background-color: #fcd1ba;">

    <router-view />

  </div>

</template>

<script>

  export default {
      name: 'App',
      data() {
        return {
          checklogin:this.$store.state.isLoggedIn,
          sessioncheckdata:'',
          usersdata:this.$store.state.NotActiveUser,
          role:this.$store.state.Role,
          username:localStorage.getItem('Username'),
        }
      },
      components:{
      }, 
      updated(){
        this.username=localStorage.getItem('Username');
      }, 
      mounted(){
      this.userdepartment()

      },
      methods: {
      
           logout(){
          this.$store.dispatch('logout');
          if(this.$store.state.isLoggedIn==false)
          {
            this.$router.push('/');

          }
          
        },
        userdepartment(){
          
          this.username=localStorage.getItem('Username');

        },
    showAlert() {
          this.$swal({
            text: 'Toast with custom target',
            target: '#custom-target',
            customClass: {
              container: 'position-absolute'
            },
            toast: true,
            position: 'top'
        });
    },
  },

  }
</script>

<style scoped>
  #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
      margin-top: 60px;
  }

  body {
      background-color: #fcd1ba;
  }
</style>
