import { createWebHashHistory, createRouter } from "vue-router";
import TmsLogin from "../views/TmsLogin.vue";
import Home from "../views/WoodlandsHome.vue";
import categories from "../views/categories.vue";

// ending manager


import NotFound from "../views/NotFound.vue";

const routes = [
    {
        path: "/",
        name: "Customer-register",
        component: TmsLogin,
        props: true,
    },
    {
      path: "/Home",
      name: "Home",
      component: Home,
     beforeEnter (to, from, next) {
          if (localStorage.getItem("isLoggedIn")==='true') {
            next()
          } else {
            next('/')
          }
       },
      meta: {
          reload: true,
        },
    },
    {
        path: '/categories/:url',
        name: 'categories',
        component: categories,
        meta: {
            reload: true,
        },
    },


    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },

    
];

const router = createRouter({
    //mode: 'history',
    linkExactActiveClass: 'active',
    history: createWebHashHistory(),
    routes,
});

export default router;