<template>
  <div class="loading" v-if="loader">
      <div class="content" style="margin-left: -102px;margin-top: -133px;">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
      </div>
  </div>

  <section id="accueil" style="background-color: #fcd1ba;">
    <div class="container-fluid" style="margin-top: 10%;">

     
   
          <div class="container">
        
        <div class="forms">
            <div class="form-content">
                <div class="login-form">

                    <img src="/Img/logo1.png" class="img-fluid" alt="" style="height:50px;width:150px">
                    <div class="title" style="align-items:center">Woodlands</div>
                          <form  @submit.prevent="checkLogin">

                          <div class="input-boxes">
                            <div class="input-box">
                                <i class="fas fa-user"></i>
                                
                                <input class="form-control text-box single-line" id="name" v-model="name" placeholder="Enter your name" type="text" required>
                            </div>
                            <div class="input-box">
                                <i class="fa fa-phone-square" aria-hidden="true" style="transform: rotateY(180deg);"></i>
                                <input class="form-control text-box single-line"   pattern="[^[6-9]\d{9}$]" maxLength="10" required id="mobile" v-model="mobile" placeholder="Enter your Mobile No" type="number">
                            </div>
                            <div class="button input-box">
                                <input type="submit" value="Submit">
                            </div>
                            
                        </div>
</form>                    
                </div>

            </div>
        </div>
    </div>
  </div>
        
      
<div class="triangle_vert"></div>

   
  </section>
</template>

<script>

export default {
    name:"TMSLogin",
    data() {
            return {
                // for new tasks
                id: this.$route.query.tableno?this.$route.query.tableno:'0', //this is the id from the browser
                loginCheck: false,
                email: '',
                password:'',
                mobile:'',
                name:'',
                query: localStorage.setItem("table", JSON.stringify(this.$route.query),18000),
                loader:false,

            };
        },
        computed: {
      },
      mounted() {
      this.mountedFun();
      
      },  
  methods: {
    mountedFun(){
      this.id=this.$route.query.tableno?this.$route.query.tableno:'0';
      this.$router.replace('/')
      let check= localStorage.getItem("isLoggedIn");
           setTimeout(() => {
            if(check==='true' || document.cookie){
              localStorage.setItem("isLoggedIn", true);
              this.$router.push('/Home');
                           
            }else
            {
              this.$router.push('/');
            }
           }, 1000);
          
    },
    
   async checkLogin()
    { 
      this.loader=true;
      
        let payload={
        name:this.name,
        mobile:this.mobile,
        table:this.id
      }
      if (/^[6,7,8,9][0-9]{0,9}$/.test(this.mobile)){

        await this.$store.dispatch('login',payload);

        setTimeout(() => {
            let check= localStorage.getItem("isLoggedIn");
           // alert(check)
            if(check==='true' || document.cookie){
              this.loader=false;
              localStorage.setItem("isLoggedIn", true);
              this.$router.push('/Home');

            }else
            {
              this.loader=false;
              console.log('error');
              
              this.mobile='';
              this.mountedFun();
              this.$router.push('/');
              this.$swal({
                   text: 'Please Enter valid Data.',
                   target: '#custom-target',
                   customClass: {
                     container: 'position-absolute'
                   },
                   toast: true,
                   position: 'top'
               });

            }
            
          }, 1000);
          
      }
      else
      {
        this.$swal({
                    text: 'Mobile Number Not valid.',
                    target: '#custom-target',
                    customClass: {
                      container: 'position-absolute'
                    },
                    toast: true,
                    position: 'top'
                });

                this.mobile='';
                this.$router.push('/');

      }  
    }
}

};
</script>
<style scoped>

body {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
}
body {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    background-color: #fff;
}
.forms .form-content .title {
    position: relative;
    font-size: 24px;
    font-weight: 500;
    color: #333;
}
#footer {
    font-size: 22px;
    background: #9c4a29;
}
.footer {
    position: fixed;
    /* left: 0; */
    bottom: 0;
    width: 100%;
    height: 50px;
    /* text-align: center; */
}
section#accueil {
  background: #ecf6f8;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}


.container {
    position: relative;
    max-width: 850px;
    width: 100%;
    background: #fff;
    padding: 40px 30px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
    perspective: 2700px;
}
img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.container{
  position: relative;
  max-width: 850px;
  width: 100%;
  background: #fff;
  padding: 40px 30px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.2);
  perspective: 2700px;
}
.container .cover{
  posght: 100%;
  width: 50%;
  z-index: 98;
  transition: all 1s ease;
  transform-origin: left;
  transform-style: preserve-3d;
}
.container #flip:checked ~ .cover{
  transform: rotateY(-180deg);
}
 .container .cover .front,
 .container .cover .back{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.cover .back{
  transform: rotateY(180deg);
  backface-visibility: hidden;
}
.container .cover::before,
.container .cover::after{
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background: #37517e;
  opacity: 0.5;
  z-index: 12;
}
.container .cover::after{
  opacity: 0.3;
  transform: rotateY(180deg);
  backface-visibility: hidden;
}
.container .cover img{
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 10;
}
.container .cover .text{
  position: absolute;
  z-index: 130;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container .forms{
  height: 100%;
  width: 100%;
  background: #fff;
}
.container .form-content{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-content .login-form,
.form-content .signup-form {
    /*width: calc(100% / 2 - 25px);*/
    width: calc(100%);
}
.forms .form-content .title{
  position: relative;
  font-size: 24px;
  font-weight: 500;
  color: #333;
}
.forms .form-content .title:before{
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 25px;
  background: #37517e;
}
.forms .signup-form  .title:before{
  width: 20px;
}
.forms .form-content .input-boxes{
  margin-top: 30px;
}
.forms .form-content .input-box{
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  margin: 10px 0;
  position: relative;
}
.form-content .input-box input{
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  padding: 0 30px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 2px solid rgba(0,0,0,0.2);
  transition: all 0.3s ease;
}
.form-content .input-box input:focus,
.form-content .input-box input:valid{
  border-color: #fcd1ba;
}
.form-content .input-box i{
  position: absolute;
  color: #37517e;
  font-size: 17px;
}
.forms .form-content .text{
  font-size: 14px;
  font-weight: 500;
  color: #333;
}
.forms .form-content .text a{
  text-decoration: none;
}
.forms .form-content .text a:hover{
  text-decoration: underline;
}
.forms .form-content .button{
  color: #fff;
  margin-top: 40px;
}
.forms .form-content .button input{
  color: #fff;
  background: #9c4a29;
  border-radius: 6px;
  padding: 0;
  cursor: pointer;
  transition: all 0.4s ease;
}
.forms .form-content .button input:hover{
  background: #fcd1ba;
}
.forms .form-content label{
  color: #fcd1ba;
  cursor: pointer;
}
.forms .form-content label:hover{
  text-decoration: underline;
}
.forms .form-content .login-text,
.forms .form-content .sign-up-text{
  text-align: center;
  margin-top: 25px;
}

</style>
