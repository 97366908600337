import { createStore } from 'vuex'
// import { isLoggedIn, logOut, logIn, ManagerMenu,UserMenu,DirectoreMenu,isManagerIn,isUserIn,isDirectoreIn } from '../shared/utils/auth'
import { isLoggedIn, logOut, logIn, UserMenu } from '../shared/utils/auth'
import axios from 'axios';
const store = createStore({
    state(){
        return{
          base_url:'',
          managerMenu:[],
          MainLogin:'',
            isLoggedIn: false,
            LoginUser:{},
            DeptList:[],
            editDeptList:[],
            Usertype:[],
            editUserType:[],
            Users:[],
            edituser:[],
            projects:[],
            editProject:[],
            TaskList:[],
            todolist:[],
            inprogresslist:[],
            testinglist:[],
            donelist:[],

            ManagerTODO:[],
            Managerinprogresslist:[],
            Managertestinglist:[],
            Managerdonelist:[],

            ManagerTEAMTODO:[],
            ManagerTEAMinprogresslist:[],
            ManagerTEAMtestinglist:[],
            ManagerTEAMdonelist:[],

            ManagerDashboardTEAMTODO:[],
            ManagerDashboardTEAMinprogresslist:[],
            ManagerDashboardTEAMtestinglist:[],
            ManagerDashboardTEAMdonelist:[],

            UserTaskDetails:[],
            Role:'',
            isManagerIn:false,
            isUserIn:false,
            isDirectorIn:false,
            DirectoreEntity:[],
            rowEntity:[],
            subProjects:[],
            DepartmentUser:[],
            rowSubProject:[],
            checkList:[],
            taskEmail:[],
            TaskActionList:[],
            loginWiseUserList:[],
            TaskCommentsList:[],
            rowed:[],
            EntityDash:[],
            UserwiseTasklist:[],

        }
    },
    mutations:{
        setUser(state, payload) {
            state.user = payload
          },
        setLoggedIn(state, payload) {
            state.isLoggedIn = payload
          },
        increment(state){
            state.count++;
        },
        updatelogindata(state,payload){
            state.LoginUser=payload;
        },
        updateDepartList(state,payload){
          state.DeptList=payload;
        },
        getDepartrecord(state,payload)
        {
          state.editDeptList=payload;
        },
        getUserType(state,payload){
          state.Usertype=payload
        },
      
      

    },
    actions:{
      menuAction({commit}){
        let role=localStorage.getItem('Role');

        if(role=='manager'){
          let payload=[{route:'dashboard'},{route:'users'},{route:'users'},]
          commit('setmenu',payload);
        }else if(role=='User'){
          let payload=[{route:'dashboard'},{route:'users'},{route:'users'},]
          commit('setmenu',payload);
        }else if(role=='Administrator'){
          let payload=[{route:'dashboard'},{route:'users'},{route:'users'},]
          commit('setmenu',payload);
        }else if(role=='Director'){
          let payload=[{route:'dashboard'},{route:'users'},{route:'users'},]
          commit('setmenu',payload);
        }

      },
        loadStoredState(context) {
            context.commit('setLoggedIn', isLoggedIn())
          },
          login({ dispatch },payload) {
            // simulate login
            dispatch('loadUser',payload)
          },
          logout({ commit }) {
            localStorage.setItem("userID",null);
            localStorage.setItem("Role",'');
            commit('setUser', {})
            commit('setLoggedIn', false)
            logOut()
          },
          async loadUser({ commit, dispatch },payload) {
            console.log('------loaduser----');
            if (!isLoggedIn()) {
              try {
                  var optionAxios = {
                  headers: {
                              'Content-Type': 'multipart/form-data',
                          }                         }

              // Make a request for a user with a given ID
              await  axios.post('https://demo2.areteonline.in/api/Customers',payload,optionAxios)
                      .then(function (res) {
                          if(res.data.status==='true' || res.data.status==='True'){
                            
                            commit('setUser', res.data)
                            localStorage.setItem("Username", payload.name);
                            localStorage.setItem("mobile",payload.mobile);
                            localStorage.setItem("table",payload.table,18000);
                           
                            document.cookie = 'Usernum' + "=" + payload.mobile; 
                            document.cookie= 'Usertype' + "="+payload.name;

                                UserMenu()
                               // commit('setUserIn', true)
                                logIn()
                                return true;
                            
                          }else
                          {
                            dispatch('logout')
                            return true;
                          }
                      })
                      .catch(function (error) {
                          // handle error
                          console.log(error);
                      })
                      .then(function () {
                          // always executed
                          console.log('---------')
                      });
              
              } catch (error) {
                dispatch('logout')
              }
            }
          },
    
    },
    getters:{
        fetchData(state){
            return state;
        },
      

    }

});

export default store;